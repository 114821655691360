.Score
{
    position: center;

    input[type=text]{
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        width: 100%;
    }
  
    button{
        padding: 10px 0px;
    }
      
    button:hover {
        opacity: 1
    }

    .yes{
        background-color: #0be345;
    }

    .no{
        background-color: #d90909;
    }
}