.Card {
    position: center;
  
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 70vh;
      width: 70vw;
    
    }

    input[type=text]{
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      width: 80%;
    }

    button{
      background-color: #e89910;
      padding: 10px 0px;
      width: 20%;
    }
    
    button:hover {
      opacity: 1
    }

}